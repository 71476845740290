import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CardProduct } from "../components/CardProduct";
import "../assets/css/list_product/list_product.css";
import { get_CategoryList, get_ProductList } from "../actions";
import { CardProductSkeleton } from "../components/CardProductSkeleton";
import { imageUrl } from "../actions/image";
import productEmpty from "../assets/img/empty-product.svg";
import { Link, useLocation } from "react-router-dom";
import iconFilter from "../assets/img/icon-filter.svg";
import MetaTags from "../helpers/MetaTags";
import { cryptoJs } from "../helpers";
import Analytics from "../helpers/Analytics";

export function ListProduct() {
  const location = useLocation();
  const [productList, setProductList] = useState([]);
  const [modalBackdrop, setBackdrop] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [listCategory, setCategory] = useState([]);
  const [onPageLoad, setPageLoad] = useState(true);
  const [timeoutFilter, setTimoutFilter] = useState("");
  const [keywordOld, setKeywordOld] = useState("");
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const [filter, setFilter] = useState({
    price: "all",
    category: "all",
    keyword: "",
    keyword_old: "",
    is_best_seller: "tidak",
    immediately: false,
    load: false,
    promoId: "",
  });

  const [itemPromo, setItemPromo] = useState({
    id: '',
    nama: '',
    exist: false,
    hash: '',
  })

  const [filterMobile, setFilterMobile] = useState({
    price: 'all',
    brand: "",
    category: "all",
    min: '',
    max: '',
    promoId: "",
  })

  const [pagination, setPaging] = useState({
    currentPage: 1,
    totalPage: 1,
    total: 0,
  });

  const [metaData, setMetaData] = useState({
    website_name: "",
    website_desc: "",
    website_keyword: "",
    address: "",
    phone: "",
    phone_number: "",
    email: "",
    coordinate: "",
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    youtube_url: "",
    phone_wa: "",
  });

  const [filterPrice, setfilterPrice] = useState([
    {
      id: "all",
      text: "All Price",
      min: "",
      max: "",
    },
    {
      id: "< 100.000",
      text: "< 100.000",
      min: "",
      max: 100000,
    },
    {
      id: "100.000 - 500.000",
      text: "100.000 - 500.000",
      min: 100000,
      max: 500000,
    },
    {
      id: "> 500.000",
      text: "> 500.000",
      min: 500001,
      max: "",
    },
  ]);

  const getList = (page = 1) => {
    var selectedPrice = filterPrice.find((e) => e.id == filter.price);
    const filterFix = {
      ...filter,
      category:
        filter.category && filter.category != "all" ? [filter.category] : "",
      min: selectedPrice.min,
      max: selectedPrice.max,
      page: page,
      pagesize: 12,
    };
    if (page == 1) {
      setProductList([]);
    }
    setPageLoad(true);
    get_ProductList(filterFix, (res) => {
      setKeywordOld(filterFix.keyword);
      const { list, paging } = res;
      if (page == 1) {
        setProductList(list);
      } else {
        setProductList([...productList, ...list]);
      }
      setPaging(paging);
      setPageLoad(false);
    });
  };

  const toggleModal = (e, value) => {
    setModalFilter(!modalFilter);
    setBackdrop(!modalBackdrop);
  };

  useEffect(() => {
    get_CategoryList((res) => {
      setCategory(res.list);
    });

    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem("_mta");
      if (meta) {
        setMetaData({ ...metaData, ...JSON.parse(meta) });
        clearInterval(intervalCheckMeta);
      }
    }, 20);

    const urlSearchParams = new URLSearchParams(location.search);
    var promoId = '';
    if (urlSearchParams.get('_a')) {
      try {
        var decrypt = cryptoJs('decrypt', urlSearchParams.get('_a'));
        var promo = JSON.parse(decrypt);
        promoId = promo.id;
        setItemPromo({ ...itemPromo, ...promo, exist: true, hash: urlSearchParams.get('_a') }); 
      } catch (error) {
        setItemPromo({ ...itemPromo, exist: false });  
      }
    } else {
      setItemPromo({ ...itemPromo, exist: false });
    }
    setFilter({
      ...filter,
      category: urlSearchParams.get("category") ?? "all",
      brand: "",
      is_best_seller: location.pathname == "/best-selling" ? "ya" : "tidak",
      immediately: true,
      load: true,
      promoId: promoId,
    });

    setFilterMobile({ ...filterMobile, category: urlSearchParams.get("category") ?? "all", })
  }, []);

  useEffect(() => {
    if (filter.load) {
      if (filter.immediately) {
        getList();
      } else {
        if (timeoutFilter) {
          clearTimeout(timeoutFilter);
        }
        var timeout = setTimeout(() => {
          getList();
        }, 1500);
        setTimoutFilter(timeout);
      }
    }
  }, [filter]);

  return (
    <>
      <Analytics title={itemPromo.exist && itemPromo.nama ? `${itemPromo.nama} - naik daun` : location.pathname == '/best-selling' ? 'Best Selling - naik daun' : location.pathname == '/product' ? 'Product - naik daun' : 'naik daun'}></Analytics>
      <MetaTags title={itemPromo.exist && itemPromo.nama ? `${itemPromo.nama} - naik daun` : location.pathname == '/best-selling' ? 'Best Selling - naik daun' : location.pathname == '/product' ? 'Product - naik daun' : 'naik daun'} url={window.location.href} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name}></MetaTags>
      {modalBackdrop && <div className="modal-backdrop"></div>}
      {modalFilter && (
        <div
          className="modal"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Filter</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={toggleModal}></button>
              </div>
              <div className="modal-body">
                <div className="list-filter-category">
                  <div
                    className={`item-filter ${filterMobile.category == "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setFilterMobile({
                        ...filterMobile,
                        immediately: true,
                        category: "all",
                      });
                    }}>
                    All Category
                  </div>
                  {listCategory.map((value, index) => (
                    <div
                      key={`category-${index}`}
                      className={`item-filter ${filterMobile.category == value.url ? "active" : ""
                        }`}
                      onClick={() => {
                        setFilterMobile({
                          ...filterMobile,
                          immediately: true,
                          category: value.url,
                        });
                      }}>
                      {value.nama}
                    </div>
                  ))}
                  {/* <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>JWA JS</div>
              <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>MEDIA TANAM</div>
              <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>MOSS POLE</div>
              <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>MOSS POLE</div>
              <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>NEEM OIL</div> */}
                </div>
                <hr />
                <div className="list-filter-category">
                  <div
                    className="item-filter active"
                    style={{ color: "#1F1F23", cursor: "default" }}>
                    Price
                  </div>
                  {filterPrice.map((value, index) => (
                    <div className="item-filter" key={`filterprice-${index}`}>
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          checked={filterMobile.price == value.id}
                          value={value.id}
                          onChange={(e) =>
                            setFilterMobile({ ...filterMobile, price: value.id })
                          }
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>{value.text}</span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" onClick={() => {
                  setFilter({ ...filter, category: filterMobile.category, price: filterMobile.price, load: true, immediately: true })
                  toggleModal();
                }} className="btn btn-green px-2 py-3">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <section id="list-product">
        <div className="container">
          <div className="title-section text-start">
            {location.pathname == "/best-selling"
              ? "Best Selling"
              : itemPromo.exist && itemPromo.nama ? itemPromo.nama : "Our Product"}
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              {itemPromo.exist && itemPromo.nama && (
                <>
                  <li className="breadcrumb-item">
                    <Link to="/our-promo">Our Promo</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {itemPromo.nama}
                  </li>
                </>
              )}
              {!itemPromo.exist && (
                <li className="breadcrumb-item active" aria-current="page">
                  List Product
                </li>
              )}
            </ol>
          </nav>
          <div className="d-flex justify-content-between justify-content-md-end flex-wrap">
            {isPhoneScreen && (

              <div
                className="d-flex btn-filter align-items-center me-2"
                onClick={() => {
                  toggleModal();
                }}>
                <div className="">
                  <img src={iconFilter} alt={iconFilter}></img>
                </div>
                <div className="ms-2">Filter</div>
              </div>

            )}
            <form
              className="d-flex search"
              onSubmit={(e) => {
                setFilter({
                  ...filter,
                  immediately: true,
                  category: "all",
                  price: "all",
                  load: true,
                });
                e.preventDefault();
              }}>
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onInput={(e) => {
                  if (keywordOld != "" && e.target.value == "") {
                    setFilter({
                      ...filter,
                      keyword: "",
                      keyword_old: "",
                      immediately: true,
                      category: "all",
                      price: "all",
                      load: true,
                    });
                  } else {
                    setFilter({
                      ...filter,
                      keyword: e.target.value,
                      immediately: false,
                      load: false,
                    });
                  }
                }}
              />
            </form>
          </div>
          <div className="row mt-5">
            {isDesktopScreen && (
              <div className="col-12 col-md-3">
                <div className="list-filter-category">
                  <div
                    className={`item-filter ${filter.category == "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        immediately: true,
                        category: "all",
                      });
                    }}>
                    All Category
                  </div>
                  {listCategory.map((value, index) => (
                    <div
                      key={`category-${index}`}
                      className={`item-filter ${filter.category == value.url ? "active" : ""
                        }`}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          immediately: true,
                          category: value.url,
                        });
                      }}>
                      {value.nama}
                    </div>
                  ))}
                  {/* <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>JWA JS</div>
              <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>MEDIA TANAM</div>
              <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>MOSS POLE</div>
              <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>MOSS POLE</div>
              <div className={`item-filter ${filter.category == '' ? 'active' : ''}`}>NEEM OIL</div> */}
                </div>
                <hr />
                <div className="list-filter-category">
                  <div
                    className="item-filter active"
                    style={{ color: "#1F1F23", cursor: "default" }}>
                    Price
                  </div>
                  {filterPrice.map((value, index) => (
                    <div className="item-filter" key={`filterprice-${index}`}>
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          checked={filter.price == value.id}
                          value={value.id}
                          onChange={(e) =>
                            setFilter({ ...filter, price: value.id })
                          }
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>{value.text}</span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="col-12 col-md-9">
              <div className="row">
                {productList.length > 0
                  ? productList.map((value, index) => (
                    <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                      <CardProduct
                        key={`item-product${index}`}
                        discount={value.satuan_promo}
                        category={value.category}
                        name={value.nama}
                        image={imageUrl.product(value.gambar)}
                        url={`${value.url}${itemPromo.exist ? '?_a='+encodeURIComponent(itemPromo.hash) : ''}`}
                        rating={value.rating}
                        review={value.total_review}
                        originalPrice={value.harga}
                        discountPrice={value.harga_promo}></CardProduct>
                    </div>
                  ))
                  : ""}

                {onPageLoad
                  ? ["", "", "", ""].map((value, index) => (
                    <div
                      className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3"
                      key={`skeleton-prodicy${index}`}>
                      <CardProductSkeleton></CardProductSkeleton>
                    </div>
                  ))
                  : ""}

                {!onPageLoad && productList.length == 0 ? (
                  <div className="text-center my-5">
                    <img
                      src={productEmpty}
                      height={100}
                      alt="empty-review"></img>
                    <div
                      style={{ color: "#9A9A9A", fontSize: "1rem" }}
                      className="mt-4">
                      Product doesn’t exist
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {!onPageLoad &&
                  pagination.lastPage &&
                  pagination.currentPage != pagination.lastPage ? (
                  <div className="text-center">
                    <button
                      type="button"
                      style={{
                        padding: "8px 16px",
                        border: "1px solid #979797",
                      }}
                      className="btn"
                      onClick={(e) => {
                        getList(pagination.currentPage + 1);
                      }}>
                      Load More
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {/* <div className="col-6 col-md-3 ">
                  <CardProduct
                    image={img1}
                    title={"Snake Plant Laurentii"}
                    type={"Medium, Well draining"}
                    price={"Rp 92.000"}></CardProduct>
                </div> */}
                {/* <div className="col-12 col-md-3">
                  <CardProduct
                    image={img1}
                    title={"Snake Plant Laurentii"}
                    type={"Medium, Well draining"}
                    price={"Rp 92.000"}></CardProduct>
                </div>
                <div className="col-12 col-md-3">
                  <CardProduct
                    image={img1}
                    title={"Snake Plant Laurentii"}
                    type={"Medium, Well draining"}
                    price={"Rp 92.000"}></CardProduct>
                </div>
                <div className="col-12 col-md-3">
                  <CardProduct
                    image={img1}
                    title={"Snake Plant Laurentii"}
                    type={"Medium, Well draining"}
                    price={"Rp 92.000"}></CardProduct>
                </div>
                <div className="col-12 col-md-3">
                  <CardProduct
                    image={img1}
                    title={"Snake Plant Laurentii"}
                    type={"Medium, Well draining"}
                    price={"Rp 92.000"}></CardProduct>
                </div>
                <div className="col-12 col-md-3">
                  <CardProduct
                    image={img1}
                    title={"Snake Plant Laurentii"}
                    type={"Medium, Well draining"}
                    price={"Rp 92.000"}></CardProduct>
                </div>
                <div className="col-12 col-md-3">
                  <CardProduct
                    image={img1}
                    title={"Snake Plant Laurentii"}
                    type={"Medium, Well draining"}
                    price={"Rp 92.000"}></CardProduct>
                </div>
                <div className="col-12 col-md-3">
                  <CardProduct
                    image={img1}
                    title={"Snake Plant Laurentii"}
                    type={"Medium, Well draining"}
                    price={"Rp 92.000"}></CardProduct>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
