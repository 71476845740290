import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import wa from "../assets/img/wa-black.svg";
import x from "../assets/img/x-black.svg";
import facebook from "../assets/img/facebook.svg";
import telegram from "../assets/img/telegram.svg";
import star from "../assets/img/star.svg";
import starOutline from "../assets/img/star-outline.svg";
import starHalf from "../assets/img/star-half.svg";
import blibli from "../assets/img/icon-blibli.svg";
import tokped from "../assets/img/icon-tokped.svg";
import shopee from "../assets/img/icon-shopee.svg";
import emptyReview from "../assets/img/empty-review.svg";
import { CardProduct } from "../components/CardProduct";
import "../assets/css/list_product/list_product.css";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Slider from "react-slick";

import "react-accessible-accordion/dist/fancy-example.css";
import Skeleton from "react-loading-skeleton";
import { CardProductSkeleton } from "../components/CardProductSkeleton";
import { convertIsi, cryptoJs, formatDate, formatMoney } from "../helpers";
import { get_ProductDetail } from "../actions";
import { imageUrl } from "../actions/image";
import FsLightbox from "fslightbox-react";
import MetaTags from "../helpers/MetaTags";
import Analytics from "../helpers/Analytics";

export function DetailProduct() {
  const location = useLocation();
  const params = useParams();
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isTabletScreen = useMediaQuery({ minWidth: 768, maxWidth: 1200});
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const [image, setImage] = useState("");
  const [loadPage, setLoadPage] = useState(true);
  const [toggler, setToggler] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState('');
  const [detailProduct, setData] = useState({
    sku: "",
    url: "",
    nama: "",
    brand: {
      nama: "",
      url: "",
      gambar: "",
    },
    category: {
      nama: "",
      url: "",
      gambar: "",
    },
    deskripsi: "",
    fitur: "",
    spesifikasi: "",
    harga: "",
    harga_promo: "",
    satuan_promo: "",
    status: "",
    link_tokped: "",
    link_shopee: "",
    link_blibli: "",
    varian_id_promo: "",
    rating: "",
    total_review: "",
    is_best_seller: "",
    warranty: "",
  });

  const [varianSelected, setVarianSelected] = useState("");
  const [varianList, setVarian] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [dataReview, setReview] = useState({
    all: [],
    list: [],
    rating: [0, 0, 0, 0, 0],
  });
  const [pagingReview, setPagingReview] = useState({
    page: 1,
    totalPage: 1,
  });
  const [otherProduct, setOtherProduct] = useState([]);
  const [itemPromo, setItemPromo] = useState({
    id: '',
    nama: '',
    exist: false,
    hash: '',
  })

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1200, min: 767 },
      items: 3,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };
  const responsiveCatalog = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1200, min: 767 },
      items: 3,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 2.5,
    },
  };
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  const perPageReview = 5;

  const getImage = (index) => {
    var dataImage = imageList.find((e) => e.index == index);
    setImage(imageUrl.product(dataImage.nama_file));
  };

  const generateStarReview = (rating) => {
    var ratingElement = [];
    var half = rating - Math.floor(rating);
    var ratingBulet = Math.floor(rating);
    var outline = 5 - rating;

    for (let i = 1; i <= ratingBulet; i++) {
      ratingElement.push(<img src={star} alt={star} className="star"></img>);
    }

    if (half > 0) {
      outline -= 1;
      ratingElement.push(
        <img src={starHalf} alt={starHalf} className="star"></img>
      );
    }

    for (let i = 0; i < outline; i++) {
      ratingElement.push(
        <img src={starOutline} alt={starOutline} className="star"></img>
      );
    }

    return ratingElement;
  };

  const checkUrl = (url) => {
    var urlFix = url;
    if (!url.includes("https://") && !url.includes("http://")) {
      urlFix = `https://${url}`;
    } else if (url.includes("http")) {
      urlFix = url.replace("http://", "https://");
    }
    return urlFix;
  };

  const getProduct = (url) => {
    const urlSearchParams = new URLSearchParams(location.search);
    var promoId = '';
    if (urlSearchParams.get('_a')) {
      try {
        var decrypt = cryptoJs('decrypt', urlSearchParams.get('_a'));
        var promo = JSON.parse(decrypt);
        promoId = promo.id;
      } catch (error) {
      }
    }
    setLoadPage(true);
    get_ProductDetail(url, {
      promoId: promoId
    }, (res) => {
      const { data, varian, image, review, other } = res;
      setData({ ...data, hasPromo: false });
      setImageList(image);
      const varianSelect = varian.map((value) => {
        return {
          value: value.id,
          label: value.nama,
          harga: value.harga,
          gambar_index: value.gambar_index,
          satuan_promo: value.satuan_promo,
          harga_promo: value.harga_promo,
          status: value.status,
        };
      });
      var varianPromo = varianSelect.find((e) => e.harga_promo);

      if (!varianPromo) {
        varianPromo = varianSelect[0];
      }
      // console.log(varianPromo ? varianPromo.gambar_index : 0)
      setVarianSelected(varianPromo ?? "");
      setVarian([...varianSelect]);
      setReview({ ...dataReview, all: review.list, rating: review.rating });
      setPagingReview({
        page: 1,
        totalPage: Math.ceil(review.list.length / perPageReview),
      });
      setLoadPage(imageUrl.product(image[0].nama_file));
      setOtherProduct(other);
      setLoadPage(false);
      var index = varianPromo ? varianPromo.gambar_index : 1;
      var dataImage = image.find((e) => e.index == index);
      setImage(imageUrl.product(dataImage.nama_file));
      // getImage(varianPromo ? varianPromo.gambar_index : 1);
    });
  };

  const zoom = (e) => {
    const zoomer = e.currentTarget;
    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;

    const x = (offsetX / zoomer.offsetWidth) * 100;
    const y = (offsetY / zoomer.offsetHeight) * 100;

    zoomer.style.backgroundPosition = `${x + 10}% ${y + 10}%`;
  };

  useEffect(() => {
    getProduct(params.url);
  }, []);

  useEffect(() => {
    if (varianList.length > 0 && varianSelected.value != "") {
      let hasPromo = false;
      var harga = detailProduct.harga;
      var harga_promo = detailProduct.harga_promo;
      var satuan_promo = detailProduct.satuan_promo;
      var status = varianSelected.status;

      if (varianSelected.harga_promo) {
        hasPromo = true;
        harga = varianSelected.harga;
        harga_promo = varianSelected.harga_promo;
        satuan_promo = varianSelected.satuan_promo;
      } else {
        hasPromo = false;
        harga = varianSelected.harga;
        harga_promo = false;
        satuan_promo = "";
      }
      // if (
      //   detailProduct.harga_promo &&
      //   detailProduct.harga_promo &&
      //   !detailProduct.varian_id_promo
      // ) {
      //   hasPromo = true;
      // } else if (varianSelected.harga_promo) {
      //   hasPromo = true;
      //   harga = varianSelected.harga;
      //   harga_promo = varianSelected.harga_promo;
      //   satuan_promo = varianSelected.satuan_promo;
      // } else if (!varianSelected.harga_promo) {
      //   harga = varianSelected.harga;
      //   harga_promo = varianSelected.harga_promo;
      //   satuan_promo = varianSelected.satuan_promo;
      // }

      setData({
        ...detailProduct,
        hasPromo: hasPromo,
        satuan_promo: satuan_promo,
        status: status,
        harga: harga,
        harga_promo: harga_promo,
      });
      if (varianSelected.gambar_index) {
        getImage(varianSelected.gambar_index);
      }
    }
  }, [varianSelected]);

  useEffect(() => {
    var start = (pagingReview.page - 1) * perPageReview;
    var end = pagingReview.page * perPageReview;
    var obj = dataReview.all.slice(start, end);
    // console.log(obj);
    setReview({ ...dataReview, list: obj });
  }, [pagingReview]);

  useEffect(() => {
    if (accordionOpen != '' && isPhoneScreen) {
      setTimeout(() => {
        var element = document.getElementById(accordionOpen);
        if (element) {
          var headerOffset = 80;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          // refDescription.current.scrollIntoView();
          window.scrollTo({
            top: offsetPosition,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 100);
    }
  }, [accordionOpen])

  return (
    <>
      {detailProduct.nama && (<Analytics title={detailProduct.nama+' - naik daun'}></Analytics>)}
      <MetaTags
        title={
          detailProduct.nama
            ? `${detailProduct.nama} - naik daun`
            : "naik daun"
        }
        description={convertIsi(detailProduct.deskripsi)}
        url={window.location.href}
        image={image}
        page="detail-product"></MetaTags>
      <section id="detail-product">
        <div className="container">
          <nav aria-label="breadcrumb " className="mt-5 md-mt-1">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Detail Product
              </li>
            </ol>
          </nav>
          <div className="row mt-5">
            <div className="col-12 col-lg-6">
              <div className="row">
                <div className="col-12 col-xl-3 order-1 order-xl-0">
                  {isDesktopScreen && (
                    <>
                      <div className="wrapper-catalog">
                        {loadPage ? (
                          <Slider {...settings}>
                            {["", "", "", ""].map((value, index) => (
                              <Skeleton
                                className="item-img-catalog"
                                key={`foto-item-${index}`}></Skeleton>
                            ))}
                          </Slider>
                        ) : (
                          <Slider {...settings}>
                            {imageList.map((value, index) => (
                              <div>
                                <img
                                  src={imageUrl.product(value.nama_file)}
                                  alt={imageUrl.product(value.nama_file)}
                                  onClick={(e) => getImage(value.index)}
                                  className="item-img-catalog"></img>
                              </div>
                            ))}
                          </Slider>
                        )}
                      </div>
                    </>
                  )}

                  {(isPhoneScreen || isTabletScreen) && (
                    <div className="wrapper-catalog mt-5">
                      {loadPage && (
                        <Carousel
                          responsive={responsiveCatalog}
                          showDots={false}
                          arrows={true}
                          draggable={false}
                          infinite={true}
                          focusOnSelect={false}>
                          {["", "", "", ""].map((value, index) => (
                            <Skeleton
                              className="item-img-catalog"
                              key={`foto-item-${index}`}></Skeleton>
                          ))}
                        </Carousel>
                      )} {!loadPage && (
                        <Carousel
                          responsive={responsiveCatalog}
                          showDots={false}
                          arrows={true}
                          draggable={false}
                          infinite={false}
                          focusOnSelect={false}>
                          {imageList.map((value, index) => (
                            <div>
                              <img
                                src={imageUrl.product(value.nama_file)}
                                alt={imageUrl.product(value.nama_file)}
                                onClick={(e) => getImage(value.index)}
                                className="item-img-catalog"></img>
                            </div>
                          ))}
                        </Carousel>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-12 col-xl-9 order-0 order-xl-1">
                  <FsLightbox
                    toggler={toggler}
                    sources={[image]}
                    type="image"
                  />
                  {(isDesktopScreen || isTabletScreen) && (
                    <>
                      {loadPage ? (
                        <Skeleton className="main-img"></Skeleton>
                      ) : (
                        <figure
                          className="zoom"
                          onMouseMove={(e) => zoom(e)}
                          style={{
                            backgroundImage: `url(${image})`,
                          }}>
                          <img
                            src={image}
                            alt={image}
                            className="main-img"
                          ></img>
                        </figure>
                      )}
                    </>
                  )}

                  {isPhoneScreen && (
                    <>
                      {loadPage ? (
                        <Skeleton className="main-img"></Skeleton>
                      ) : (
                        <img
                          src={image}
                          alt={image}
                          className="main-img"
                          onClick={() => setToggler(!toggler)}></img>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="title-product font-500">
                {loadPage ? (
                  <Skeleton width={"80%"} height={40}></Skeleton>
                ) : (
                  detailProduct.nama
                )}
              </div>
              {loadPage ? (
                <div className="d-flex align-items-center mt-3">
                  <div className="price-product me-auto">
                    <Skeleton width={150} height={25}></Skeleton>
                  </div>
                  <div className="d-flex review">
                    <div className="">
                      <Skeleton width={90}></Skeleton>
                    </div>
                    <div className="line mx-2">|</div>
                    <div className="">
                      <Skeleton width={90}></Skeleton>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="d-flex align-items-center mt-3 flex-wrap">
                    {detailProduct.harga_promo &&
                      detailProduct.harga_promo != "0" ? (
                      <div className="price-product me-auto">
                        <span className="original">{`Rp ${formatMoney(
                          detailProduct.harga
                        )}`}</span>
                        <span className="discount">{`Rp ${formatMoney(
                          detailProduct.harga_promo
                        )}`}</span>
                      </div>
                    ) : (
                      <div className="price-product me-auto">{`Rp ${formatMoney(
                        detailProduct.harga
                      )}`}</div>
                    )}
                    <div className="d-flex align-items-center review">
                      {/* <div className=""> */}
                        <img src={star} alt={star} className={"me-1"}></img>
                      {/* </div> */}
                      <div className="">{detailProduct.rating}</div>
                      <div className="line mx-2">|</div>
                      <div className="">
                        {detailProduct.total_review} Reviews
                      </div>
                    </div>
                    <div className="d-flex share">
                      <div className="me-2">Share :</div>
                      <Link
                        target="_blank"
                        to={`https://wa.me/?text=${window.location.href}`}>
                        <img src={wa} alt={wa} className="me-2"></img>
                      </Link>
                      <Link
                        target="_blank"
                        to={`https://twitter.com/intent/tweet?text=${window.location.href}`}>
                        <img src={x} alt={x} className="me-2"></img>
                      </Link>
                      <Link
                        target="_blank"
                        to={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}>
                        <img src={facebook} alt={facebook} className="me-2"></img>
                      </Link>
                      <Link
                        target="_blank"
                        to={`https://t.me/share/url?url=${window.location.href}`}>
                        <img src={telegram} alt={telegram} className="me-2"></img>
                      </Link>
                      {/* <img src={wa} alt={wa}></img>
                      <img src={x} alt={x} className="mx-2"></img>
                      <img src={ig} alt={ig}></img> */}
                    </div>
                  </div>
                  {varianList.length > 0 ? (
                    <>
                      <hr></hr>
                      <div className="mt-3">
                        <div className="detail-title mb-3">Variant</div>
                        <div className="row">
                          {varianList.map((value, index) => (
                            <div className="col-6 col-sm-4">
                              <div
                                key={`varian-${value}`}
                                className={`varian-item ${varianSelected.value == value.value
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={(e) => {
                                  setVarianSelected(value);
                                }}>
                                {value.label}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              <hr></hr>
              {loadPage ? (
                <div className="my-3">
                  <Skeleton
                    width={"30%"}
                    height={55}
                    count={3}
                    className="me-2"
                    containerClassName="d-flex justify-content-center"></Skeleton>
                </div>
              ) : (
                <div className="my-3">
                  {detailProduct.link_blibli ? (
                    <Link
                      to={checkUrl(detailProduct.link_blibli)}
                      target="_blank"
                      className="btn btn-checkout btn-blue font-500 me-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={blibli} alt={blibli}></img>
                        <div className="ms-2">Buy at Blibli</div>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                  {detailProduct.link_shopee ? (
                    <Link
                      to={checkUrl(detailProduct.link_shopee)}
                      target="_blank"
                      className="btn btn-checkout btn-orange font-500 me-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={shopee} alt={shopee}></img>
                        <div className="ms-2">Buy at Shopee</div>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                  {detailProduct.link_blibli ? (
                    <Link
                      to={checkUrl(detailProduct.link_blibli)}
                      target="_blank"
                      className="btn btn-checkout btn-light-green font-500">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={tokped} alt={tokped}></img>
                        <div className="ms-2">Buy at Tokped</div>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {!loadPage ? (
                <div>
                  <Accordion allowZeroExpanded>
                    <AccordionItem id="description">
                      <AccordionItemHeading onClick={(e) => {
                          if (accordionOpen == "description") {
                            setAccordionOpen('');
                          } else {
                            // scrool to ref
                            setAccordionOpen("description");
                          }
                        }}> 
                        <AccordionItemButton>Description</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: detailProduct.deskripsi,
                          }}></div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem id="spesifikasi">
                      <AccordionItemHeading onClick={(e) => {
                          if (accordionOpen == "spesifikasi") {
                            setAccordionOpen('');
                          } else {
                            // scrool to ref
                            setAccordionOpen("spesifikasi");
                          }
                        }}>
                        <AccordionItemButton>Our Guarantee</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: detailProduct.fitur,
                          }}></div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              ) : (
                <>
                  <hr></hr>
                  <Skeleton width={"100%"} height={60} count={2}></Skeleton>
                </>
              )}
            </div>
          </div>

          <div className="mt-5">
            <div
              style={{
                fontSize: 18,
                fontWeight: 500,
                fontFamily: "General Sans",
                color: "#1F1F23",
              }}>
              Reviews
            </div>
            <div className="d-flex mt-3 pt-3 flex-wrap align-items-center">
              <div className="wrapper-rating">
                <div
                  className={`d-flex justify-content-center justify-conten-md-start ${loadPage ? "justify-content-center" : ""
                    }`}>
                  {!loadPage ? (
                    <img src={star} alt={star} className="star"></img>
                  ) : (
                    ""
                  )}
                  <div className="rating ">
                    {loadPage ? (
                      <Skeleton width={80} height={40}></Skeleton>
                    ) : (
                      detailProduct.rating
                    )}
                  </div>
                </div>
                <div className="total-reviews text-center">
                  {loadPage ? (
                    <Skeleton width={130} height={20}></Skeleton>
                  ) : (
                    `${detailProduct.total_review} reviews`
                  )}
                </div>
              </div>
              <div className="wrapper-review-bar">
                {loadPage ? (
                  ["", "", "", "", ""].map((e, i) => (
                    <div className="d-flex align-items-center review-bar">
                      <Skeleton width={20} height={20}></Skeleton>
                      <div className="ms-2">
                        <Skeleton width={150} height={20}></Skeleton>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    {dataReview.rating.map((value, index) => (
                      <div
                        className="d-flex align-items-center review-bar"
                        key={`review-number${index}`}>
                        <img src={star} alt={star} className="star"></img>
                        <div className="total-bar font-500">{5 - index}</div>
                        <div className="wrapper-bar">
                          <div
                            className="bar"
                            style={{
                              width: `${value
                                ? (value / detailProduct.total_review) * 100
                                : 0
                                }%`,
                            }}></div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="list-reviewer">
              {loadPage ? (
                ["", ""].map((e, i) => (
                  <div className="item-reviewer" key={`skeleton-review1-${i}`}>
                    <div className="d-flex align-items-center mb-3">
                      <div>
                        <div className="name">
                          <Skeleton width={150} height={20}></Skeleton>
                        </div>
                        <div className="d-flex">
                          <Skeleton width={250} height={20}></Skeleton>
                          {/* <div className="date">2 Juli 2023</div> */}
                        </div>
                      </div>
                    </div>
                    <div className="desc">
                      <div className="mt-3">
                        <Skeleton
                          width={"100%"}
                          height={"10"}
                          count={2}></Skeleton>
                        <Skeleton width={"40%"} height={"10"}></Skeleton>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {dataReview.list.map((value, index) => (
                    <div className="item-reviewer" key={`review-item${index}`}>
                      <div className="d-flex align-items-center mb-3">
                        {/* <div className="">
                                  <img
                                    src={review}
                                    alt={review}
                                    className="img-reviewer"></img>
                                </div> */}
                        <div>
                          <div className="name">{value.nama}</div>
                          <div className="d-flex">
                            {generateStarReview(parseFloat(value.nilai))}
                            <div className="date">
                              {formatDate(value.waktu)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="desc">{value.review}</div>
                    </div>
                  ))}
                </>
              )}
              {!loadPage && dataReview.all.length == 0 ? (
                <div className="text-center my-4">
                  <img src={emptyReview} height={100} alt="empty-review"></img>
                  <div
                    style={{ color: "#9A9A9A", fontSize: "1rem" }}
                    className="mt-4">
                    No review yet
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-center mt-3">
                {pagingReview.page > 1 ? (
                  <>
                    <button
                      className="btn me-3"
                      style={{
                        padding: "8px 16px",
                        border: "1px solid #979797",
                      }}
                      onClick={() =>
                        setPagingReview({
                          ...pagingReview,
                          page: pagingReview.page - 1,
                        })
                      }>
                      Prev
                    </button>{" "}
                  </>
                ) : (
                  ""
                )}
                {dataReview.all.length > 0 &&
                  pagingReview.page != pagingReview.totalPage ? (
                  <>
                    <button
                      className="btn"
                      style={{
                        padding: "8px 16px",
                        border: "1px solid #979797",
                      }}
                      onClick={() =>
                        setPagingReview({
                          ...pagingReview,
                          page: pagingReview.page + 1,
                        })
                      }>
                      Next
                    </button>{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="title-section">You Might Also Like</div>
            <div className="mt-4">
              {loadPage ? (
                <Carousel
                  responsive={responsive}
                  showDots={false}
                  arrows={false}
                  draggable={false}
                  infinite={true}
                  focusOnSelect={false}>
                  {["", "", "", ""].map((value, index) => (
                    <CardProductSkeleton
                      key={`other-skeleton-${index}`}></CardProductSkeleton>
                  ))}
                </Carousel>
              ) : (
                <Carousel
                  responsive={responsive}
                  showDots={false}
                  arrows={true}
                  draggable={false}
                  infinite={true}
                  focusOnSelect={false}>
                  {otherProduct.map((value, index) => (
                    <CardProduct
                      onClick={() => {
                        getProduct(value.url);
                      }}
                      discount={value.satuan_promo}
                      category={value.category}
                      name={value.nama}
                      image={imageUrl.product(value.gambar)}
                      url={value.url}
                      rating={value.rating}
                      review={value.total_review}
                      originalPrice={value.harga}
                      discountPrice={value.harga_promo}></CardProduct>
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
