import { Link } from "react-router-dom";
import { CardBlog } from "../../components/CardBlog";
import { CardBlogSkeleton } from "../../components/CardBlogSekeleton";
import { imageUrl } from "../../actions/image";

export function OurPost({ isLoading, list }) {
  return (
    <>
      <section id="ourPost">
        <div className="container">
          <div className="title-section">OUR LATEST BLOG</div>
          <div className="sub-title-section mt-2">
            Stay Rooted in Knowledge: Naik Daun's Freshest Garden Insights and
            Tips
          </div>
          <div className="row align-items-center mt-5">
            {isLoading
              ? ["", "", ""].map((value, index) => (
                  <div
                    className="col-12 col-md-6 col-lg-4"
                    key={`skeleton-product${index}`}>
                    <CardBlogSkeleton></CardBlogSkeleton>
                  </div>
                ))
              : list.map((value, index) =>
                  index < 3 ? (
                    <div className="col-12 col-md-6 col-lg-4" key={`item-blog${index}`}>
                      <CardBlog
                        image={imageUrl.blog(value.gambar)}
                        title={value.judul}
                        url={value.url}></CardBlog>
                    </div>
                  ) : (
                    ""
                  )
                )}
          </div>
          <div className="text-center">
            <Link to="/blog" className="btn btn-more secondary font-500 mt-4">
              View More
              <svg
                className="ms-1"
                width="25"
                height="25"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23.955 21.273L23.955 8.54505L11.2271 8.54505"
                  stroke="#1F1F23"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.04508 24.4549L23.955 8.54504"
                  stroke="#1F1F23"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
