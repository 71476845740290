import React from "react";
import { CardMaps } from "../../components/CardMaps";

export function Maps({metaData}) {
  return (
    <>
      <CardMaps className="h-65" coordinate={metaData.coordinate} address={metaData.address}></CardMaps>
    </>
  );
}
