import React, { useEffect, useState } from "react";
import ornament from "../../assets/img/ornament.png";
import { CardBlog } from "../../components/CardBlog";
import { Link, useLocation } from "react-router-dom";
import { CardBlogSkeleton } from "../../components/CardBlogSekeleton";
import { imageUrl } from "../../actions/image";
import emptyBlog from '../../assets/img/empty-blog.svg';
import { get_BlogList } from "../../actions";
import MetaTags from "../../helpers/MetaTags";
import Analytics from "../../helpers/Analytics";

export function ListBlog() {
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [listBlog, setListBlog] = useState([]);
  const [filter, setFilter] = useState({
    keyword: '',
    immediately: false,
  });
  const [timeoutFilter, setTimoutFilter] = useState('')
  const [pagination, setPaging] = useState({
    page: 1,
    totalPage: 1
  })

  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: ''
  })

  const getListBlog = (page = 1, kategori = '', keyword = '') => {
    const urlSearchParams = new URLSearchParams(location.search);
    setLoading(true)

    get_BlogList({
      keyword: keyword,
      kategori: kategori,
      page: page,
    }, (res) => {
      setFilter({...filter, keyword_old: keyword, immediately: false})
      const { last_page, current_page, data } = res.list
      if (page == 1) {
        setListBlog(data);
      } else {
        setListBlog([...listBlog, ...data]);
      }
      setPaging({
        page: current_page,
        totalPage: last_page
      })
      setLoading(false)
    })
  }

  useEffect(() => {

    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)

    const urlSearchParams = new URLSearchParams(location.search);
    setLoading(true);
    getListBlog(1, urlSearchParams.get('category'), urlSearchParams.get('keyword'));
  }, [])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    if (filter.immediately) {
      setListBlog([]);
      getListBlog(1, urlSearchParams.get('category'), filter.keyword);
    } else {
      // if (timeoutFilter) {
      //   clearTimeout(timeoutFilter);
      // }
      // var timeout = setTimeout(() => {
      //   getListBlog(1, urlSearchParams.get('category'), filter.keyword);
      // }, 1500);
      // setTimoutFilter(timeout);
    }
  }, [filter])

  return (
    <>
      <Analytics title={'Blog - naik daun'}></Analytics>
      <MetaTags title={ 'Blog - naik daun'} url={window.location.href} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name}></MetaTags>
      <section id="post-page" className="section-page">
        <img src={ornament} alt={ornament} className="ornament"></img>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="title-section text-start">Naik Daun Blog</div>
              <div className="desc text-start mt-2">
                Embark on a botanical adventure with Naik Daun’s team of experts
                as we delve into a myriad of plant care topics to inspire and
                empower the next generation of plant enthusiasts. Join us on the
                journey to green living and thriving with confidence. Welcome to
                the Naik Daun Greenery Guide! 🌿
              </div>
            </div>
          </div>


          <div className="row mt-4">
            <div className="col-12 col-md-7">
              <form onSubmit={e => {
                setFilter({...filter, immediately: true})
                e.preventDefault();
              }}>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.66732 14C11.1651 14 14.0007 11.1645 14.0007 7.66671C14.0007 4.1689 11.1651 1.33337 7.66732 1.33337C4.16951 1.33337 1.33398 4.1689 1.33398 7.66671C1.33398 11.1645 4.16951 14 7.66732 14Z"
                        stroke="#1F1F23"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.6673 14.6667L13.334 13.3334"
                        stroke="#1F1F23"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <input
                    className="form-control s me-2"
                    type="search"
                    placeholder="Search blog"
                    aria-label="Search"
                    onInput={(e) => {
                      if (filter.keyword_old != "" && e.target.value == "") {
                        setFilter({...filter, keyword: "", keyword_old: "", immediately: true});
                      } else {
                        setFilter({...filter, keyword: e.target.value, immediately: false})
                      }
                      // const urlSearchParams = new URLSearchParams(location.search);
                      // getListBlog(1, urlSearchParams.get('category'), )
                    }}
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="row mt-5">
            {listBlog.length > 0 ?
              listBlog.map((value, index) => (
                <div className="col-12 col-md-6 col-lg-4" key={`item-blog${index}`}>
                  <CardBlog
                    image={imageUrl.blog(value.gambar)}
                    title={value.judul}
                    url={value.url}></CardBlog>
                </div>
              )) : ''}

            {isLoading ?
              ['', '', ''].map((value, index) => (
                <div className="col-12 col-md-6 col-lg-4" key={`skeleton-blog${index}`}>
                  <CardBlogSkeleton></CardBlogSkeleton>
                </div>
              ))
              : ''}

            {!isLoading && listBlog.length == 0 ?
              <div className="text-center my-5">
                <img src={emptyBlog} height={100}
                  alt="empty-review"></img>
                <div style={{ color: '#9A9A9A', fontSize: '1rem' }} className="mt-4">No content found</div>
              </div>
              : ''}

            {!isLoading && pagination.totalPage && pagination.page != pagination.totalPage ?
              <div className="text-center">
                <button type="button" style={{
                  padding: "8px 16px",
                  border: '1px solid #979797'
                }} className="btn" onClick={(e) => {
                  const urlSearchParams = new URLSearchParams(location.search);
                  getListBlog(pagination.page + 1, urlSearchParams.get('category'), urlSearchParams.get('keyword'))
                }}>Load More</button>
              </div>
              : ''}
          </div>
        </div>
      </section>
    </>
  );
}
