import React from "react";
import PropTypes from "prop-types";

const getYoutubeId = (url) => {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  }

  console.log('The supplied URL is not a valid youtube URL');

  return '';
}

const YoutubeEmbed = ({ isLoading, data }) => !isLoading && data && data.link_video && getYoutubeId(data.link_video) &&  (
  <>
    <div className="container">
      <div className="video-responsive">
        <iframe
          embedId="youtubeId"
          width="100%"
          src={`https://www.youtube.com/embed/${getYoutubeId(data.link_video)}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  </>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
