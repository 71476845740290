import { Link } from 'react-router-dom';
import star from '../assets/img/star.svg'
import { formatMoney } from '../helpers';

export function CardProduct(props) {
  const { discount, category, name, image, rating, review, originalPrice, discountPrice, url, onClick } = props;
  const checkSatuan = (string) => {
    if (string && string.toString().includes('%')) {
      let numberValue = parseFloat(string) || 0; // Handle non-numeric input
      let formattedPercentage = numberValue.toString() + "%";
      return formattedPercentage;
    }

    return string.replace('Rp', 'Rp ');
  }
  return (
    <>
      <Link to={url ? `/product/${url}` : `/product/detail`} onClick={(e) => {
        if (typeof onClick == "function") {
          onClick();
        }
      }}>
        <div className="item-product">
          <div className="card-img">
            <img src={image} alt={image} className='img-product'></img>
          </div>

          <div className="title">{name}</div>
          <div className="d-flex align-items-center mt-2 review">
            {/* <div className=""> */}
              <img src={star} alt={star}></img>
            {/* </div> */}
            <div className="mx-1">{rating ?? 0}</div>
            <div className="line mx-1">|</div>
            <div className="total-review">{review} Reviews</div>
          </div>
          {discountPrice ?
            <div className="price">
              <span className='original'>{`Rp ${formatMoney(originalPrice)}`}</span>
              <span className='discount'>{`Rp ${formatMoney(discountPrice)}`}</span>
            </div>
            :
            <div className="price">{`Rp ${formatMoney(originalPrice)}`}</div>
          }
        </div>
      </Link>
    </>
  );
}
