import "./App.css";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";

import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { Home } from "./pages/Home";
import { ListProduct } from "./pages/ListProduct";
import { DetailProduct } from "./pages/DetailProduct";
import { AboutUs } from "./pages/AboutUs";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsConditions } from "./pages/TermsConditions";
import { Warranty } from "./pages/Warranty";
import { ListBlog } from "./pages/Blog/ListBlog";
import { DetailBlog } from "./pages/Blog/DetailBlog";
import { Contact } from "./pages/Contact";
import { ScrollTop } from "./helpers/ScrollTop";

import "./assets/css/main/main.css";
import { useEffect, useState } from "react";
import { get_MetaData } from "./actions";
import MetaTags from "./helpers/MetaTags";
import { ListPromo } from "./pages/ListPromo";
import { OurPromo } from "./pages/OurPromo";
import ReactGA from 'react-ga4';
import { getAnalyticsId } from "./helpers";

function App() {
  ReactGA.initialize(getAnalyticsId());
  
  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: ''
  });

  useEffect(() => {
    get_MetaData((res) => {
      setMetaData(res.default);
      localStorage.setItem('_mta', JSON.stringify(res.default));
    });
  }, [])

  return (
    <div className="App">
      <Router>
        <MetaTags title={'naik daun'} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name}></MetaTags>
        <ScrollTop></ScrollTop>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/product" element={<ListProduct />}></Route>
          <Route path="/best-selling" element={<ListProduct />}></Route>
          <Route path="/product/:url" element={<DetailProduct />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-conditions" element={<TermsConditions />}></Route>
          <Route path="/warranty" element={<Warranty />}></Route>
          <Route path="/blog" element={<ListBlog />}></Route>
          <Route path="/blog/:url" element={<DetailBlog />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/list-promo" element={<ListProduct />}></Route>
          <Route path="/our-promo" element={<OurPromo />}></Route>

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
