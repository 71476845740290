import React, { useEffect, useState } from "react";
import banner from "../assets/img/banner-aboutus.png";
import Skeleton from "react-loading-skeleton";
import { get_AboutUs } from "../actions";
import MetaTags from "../helpers/MetaTags";
import { imageUrl } from "../actions/image";
import Analytics from "../helpers/Analytics";

export function AboutUs() {
  const [isLoading, setLoading] = useState(true);
  const [content, setContent] = useState({
    first: {
      content: '',
      gambar: '',
      title: ''
    },
    second: {
      content: '',
      gambar: '',
      title: ''
    },
  })

  const [metaData, setMetaData] = useState({
    website_name: "",
    website_desc: "",
    website_keyword: "",
    address: "",
    phone: "",
    phone_number: "",
    email: "",
    coordinate: "",
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    youtube_url: "",
    phone_wa: "",
  });

  useEffect(() => {
    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem("_mta");
      if (meta) {
        setMetaData({ ...metaData, ...JSON.parse(meta) });
        clearInterval(intervalCheckMeta);
      }
    }, 20);
    setLoading(true);
    get_AboutUs((res) => {
      const { first, second } = res
      setContent({
        first: first,
        second: second
      })
      // var split = data.content.split(`<div style="page-break-after:always"><span style="display:none">&nbsp;</span></div>`);
      // var first = split.shift();
      // setContent({
      //   first: first,
      //   second: split.length > 0 ? split.join(`<div style="page-break-after:always"><span style="display:none">&nbsp;</span></div>`) : ''
      // })

      setLoading(false);
    })
  }, [])
  return (
    <>
    
      <Analytics title={'About Us - naik daun'}></Analytics>
      <MetaTags
        title={"About Us - naik daun"}
        url={window.location.href}
        description={metaData.website_desc}
        keyword={metaData.website_keyword}
        site_nime={metaData.website_name}
        image={content.first.gambar ? imageUrl.blog(content.first.gambar) : banner}></MetaTags>
      <section id="about-us-page" className="section-page">
        <div className="container">
          {isLoading ? <>
            <Skeleton height={50} width={200}></Skeleton>
            <div className="mt-3"></div>
            <Skeleton height={10} count={5}></Skeleton>
            <Skeleton width={'40%'} height={10}></Skeleton>
            <div className="my-3">
              <Skeleton height={250} borderRadius={5}></Skeleton>
            </div>
            <Skeleton height={10} count={5}></Skeleton>
            <Skeleton width={'40%'} height={10}></Skeleton>
          </> : <>
            <div className="title-section text-start">Welcome</div>
            <div className="desc" dangerouslySetInnerHTML={{ __html: content.first.content }}>
            </div>
            <img src={content.first.gambar ? imageUrl.blog(content.first.gambar) : banner} alt={'about-us-image'} className="img-banner"></img>
            <div className="desc" dangerouslySetInnerHTML={{ __html: content.second.content }}></div>
          </>}
        </div>
      </section>
    </>
  );
}
