import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Link } from "react-router-dom";

export function CardMaps(props) {
  const { height, coordinate, address, className } = props;

  const [center, setCenter] = useState({
    lat: -7.860953007821465,
    lng: 110.37443921265516,
  });
  const containerStyle = {
    width: "100%",
    height: height,
  };
  useEffect(() => {
    if (coordinate) {
      var split = coordinate.split(',')
      setCenter({
          lat: parseFloat(split[0]),
          lng: parseFloat(split[1]),
      });
    }
  }, [coordinate]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCVcBFOy1d-5h_VLjgIRZWYvz4Kxa2W1W4",
  });

  const [map, setMap] = React.useState(null);

  return isLoaded ? (
    <div className="maps">
      <div className="info">
        <div className="title">NAIK DAUN</div>
        <div className="address">
          {address ? address : '-'}
        </div>
        <Link to={`https://maps.google.com/?q=${coordinate}`} target="_blank" className="btn btn-more primary font-500 mt-4">
          Open Maps
          <svg
            width="25"
            height="25"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.955 20.773L23.955 8.04511L11.2271 8.04511"
              stroke="white"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.04518 23.9548L23.9551 8.04492"
              stroke="white"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
      <GoogleMap mapContainerClassName={className} zoom={16} center={center} options={{ 
        scaleControl: false,
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
       }}>
        <Marker position={center} />
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}
