import Skeleton from 'react-loading-skeleton';
import star from '../assets/img/star.svg'

export function CardProductSkeleton() {
  return (
    <>
      <div className="item-product">
        <div className="card-img">
          <Skeleton className='img-product'></Skeleton>
        </div>

        <div className="title"><Skeleton height={30}></Skeleton></div>
        <div className="d-flex align-items-center mt-2 review">
          <div className="me-1"><Skeleton width={90}></Skeleton></div>
          <div className="line mx-1">|</div>
          <div className="total-review"><Skeleton width={90}></Skeleton></div>
        </div>
        <div className="price"><Skeleton height={30}></Skeleton></div>
      </div>
    </>
  );
}
