import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CardProduct } from "../../components/CardProduct";

import { Link } from "react-router-dom";
import { CardProductSkeleton } from "../../components/CardProductSkeleton";
import { imageUrl } from "../../actions/image";

export function BestSelling({ isLoading, list }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2.5,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };
  return (
    <>
      <section id="bestSelling">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-4 text-start">
              <div className="title-section">BEST SELLING</div>
              <div className="sub-title-section mt-2">
                Explore Our Blossoming Favorites: Naik Daun's Best-Selling
                Selections
              </div>
              <Link
                to="/best-selling"
                className="btn btn-more secondary font-500 mt-4">
                View More
                <svg
                  className="ms-1"
                  width="25"
                  height="25"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.955 21.273L23.955 8.54505L11.2271 8.54505"
                    stroke="#1F1F23"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.04508 24.4549L23.955 8.54504"
                    stroke="#1F1F23"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
            <div className="col-12 col-md-8">
              <div className="carousel-best-selling">
                {isLoading &&
                  (<Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                      {['', '', '', ''].map((value, index) => (
                        <CardProductSkeleton key={`bestseller-skeleton${index}`}></CardProductSkeleton>
                      ))}
                  </Carousel>)}
                  {!isLoading && (<Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={true}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {list.map((value, index) => (
                      <CardProduct
                      discount={value.satuan_promo}
                      category={value.category}
                      name={value.nama}
                      image={imageUrl.product(value.gambar)}
                      url={value.url}
                      rating={value.rating}
                      review={value.total_review}
                      originalPrice={value.harga}
                      discountPrice={value.harga_promo}></CardProduct>
                    ))}
                  </Carousel>)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
