import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CardProduct } from "../components/CardProduct";
import "../assets/css/list_product/list_product.css";
import { get_CategoryList, get_ProductList, get_PromoList } from "../actions";
import { CardProductSkeleton } from "../components/CardProductSkeleton";
import { imageUrl } from "../actions/image";
import productEmpty from "../assets/img/empty-product.svg";
import { Link, useLocation } from "react-router-dom";
import iconFilter from "../assets/img/icon-filter.svg";
import MetaTags from "../helpers/MetaTags";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { get_HomeData } from "../actions";
import Skeleton from "react-loading-skeleton";
import { cryptoJs } from "../helpers";
import Analytics from "../helpers/Analytics";
export function OurPromo() {
  const [isLoading, setLoading] = useState(true);
  const [ourPromo, setOurPromo] = useState([]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2.5,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };
  const location = useLocation();

  const [metaData, setMetaData] = useState({
    website_name: "",
    website_desc: "",
    website_keyword: "",
    address: "",
    phone: "",
    phone_number: "",
    email: "",
    coordinate: "",
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    youtube_url: "",
    phone_wa: "",
  });

  const getOurPromo = () => {
    setLoading(true)
    get_PromoList({}, (res) => {
      const { promo } = res;
      var promoFix = promo.map((item) => {
        var hash = encodeURIComponent(cryptoJs('encrypt', JSON.stringify({
          id: item.id,
          nama: item.nama
        })));

        return {...item, hash: hash}
      })
      setLoading(false);
      setOurPromo(promoFix)
    });
  };
  useEffect(() => {
    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)
    getOurPromo();
  }, []);

  return (
    <>
      <Analytics title={'Our Promo - naik daun'}></Analytics>
      <MetaTags
        title={"Our Promo - naik daun"}
        url={window.location.href}
        description={metaData.website_desc}
        keyword={metaData.website_keyword}
        site_nime={metaData.website_name}></MetaTags>

      <section
        id="bestSelling"
        className="section-promo section-page our-promo">
        <div className="container">
          <div className="title-section text-start">Our Promo</div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Our Promo
              </li>
            </ol>
          </nav>
          {isLoading && (['', '', ''].map((item, i) => (
            <div className="row align-items-center mt-5" key={`adas${i}`}>
            <div className="col-12 col-md-4 text-start position-relative">
              {/* <img src={imgPromo} alt={imgPromo} className="img-promo"></img> */}
              <Skeleton className="img-promo"></Skeleton>
              <div className="content-wrapper">
                <div className="title-section text-center text-uppercase">
                <Skeleton width={150} height={35} style={{ marginLeft: '20px' }}></Skeleton>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="mt-3">
                {isLoading ? (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {["", "", "", ""].map((value, index) => (
                      <CardProductSkeleton
                        key={`bestseller-skeleton${index}`}></CardProductSkeleton>
                    ))}
                  </Carousel>
                ) : (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={true}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {ourPromo.map((value, index) => (
                      <CardProduct
                        discount={value.satuan_promo}
                        category={value.category}
                        name={value.nama}
                        image={imageUrl.product(value.gambar)}
                        url={value.url}
                        rating={value.rating}
                        review={value.total_review}
                        originalPrice={value.harga}
                        discountPrice={value.harga_promo}></CardProduct>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
          )))}
          {!isLoading && (ourPromo.map((item, i) => (
          <div className="row align-items-center mt-3" key={`asda${i}`}>
          <div className="col-12 col-md-4 text-start position-relative">
            <img src={imageUrl.product(item.thumbnail ?? 'img-promo-n.png')} alt={`thumbnail${i}`} className="img-promo"></img>
            <div className="content-wrapper">
              <div className="title-section text-center text-uppercase">
                {item.nama}
              </div>
              <div className="text-center">
                <Link
                  to={`/list-promo?_a=${item.hash}`}
                  className="btn btn-more secondary font-500 mt-4">
                  View More
                  <svg
                    className="ms-1"
                    width="26"
                    height="26"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M24.455 20.773L24.455 8.04511L11.7271 8.04511"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.54518 23.9548L24.4551 8.04492"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8">
            <div className="mt-3">
                <Carousel
                  responsive={responsive}
                  showDots={false}
                  arrows={true}
                  draggable={false}
                  infinite={true}
                  focusOnSelect={false}>
                  {item.list.map((value, index) => (
                    <CardProduct
                      discount={value.satuan_promo}
                      category={value.category}
                      name={value.nama}
                      image={imageUrl.product(value.gambar)}
                      url={value.url+'?_a='+item.hash}
                      rating={value.rating}
                      review={value.total_review}
                      originalPrice={value.harga}
                      discountPrice={value.harga_promo}></CardProduct>
                  ))}
                </Carousel>
            </div>
          </div>
        </div>
          )))}
          {/* <div className="row align-items-center mt-5">
            <div className="col-12 col-md-4 text-start position-relative">
              <img src={imgPromo} alt={imgPromo} className="img-promo"></img>
              <div className="content-wrapper">
                <div className="title-section  text-center text-uppercase">
                  Jawa <br></br> Discount
                </div>
                <div className="text-center">
                  <Link
                    to="/our-promo"
                    className="btn btn-more secondary font-500 mt-4">
                    View More
                    <svg
                      className="ms-1"
                      width="26"
                      height="26"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M24.455 20.773L24.455 8.04511L11.7271 8.04511"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.54518 23.9548L24.4551 8.04492"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="mt-3">
                {isLoading ? (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {["", "", "", ""].map((value, index) => (
                      <CardProductSkeleton
                        key={`bestseller-skeleton${index}`}></CardProductSkeleton>
                    ))}
                  </Carousel>
                ) : (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={true}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {ourPromo.map((value, index) => (
                      <CardProduct
                        discount={value.satuan_promo}
                        category={value.category}
                        name={value.nama}
                        image={imageUrl.product(value.gambar)}
                        url={value.url}
                        rating={value.rating}
                        review={value.total_review}
                        originalPrice={value.harga}
                        discountPrice={value.harga_promo}></CardProduct>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-12 col-md-4 text-start position-relative">
              <img src={imgPromo} alt={imgPromo} className="img-promo"></img>
              <div className="content-wrapper">
                <div className="title-section text-center text-uppercase">
                  12 .12 <br></br> Discount
                </div>
                <div className="text-center">
                  <Link
                    to="/our-promo"
                    className="btn btn-more secondary font-500 mt-4">
                    View More
                    <svg
                      className="ms-1"
                      width="26"
                      height="26"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M24.455 20.773L24.455 8.04511L11.7271 8.04511"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.54518 23.9548L24.4551 8.04492"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="mt-3">
                {isLoading ? (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {["", "", "", ""].map((value, index) => (
                      <CardProductSkeleton
                        key={`bestseller-skeleton${index}`}></CardProductSkeleton>
                    ))}
                  </Carousel>
                ) : (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={true}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {ourPromo.map((value, index) => (
                      <CardProduct
                        discount={value.satuan_promo}
                        category={value.category}
                        name={value.nama}
                        image={imageUrl.product(value.gambar)}
                        url={value.url}
                        rating={value.rating}
                        review={value.total_review}
                        originalPrice={value.harga}
                        discountPrice={value.harga_promo}></CardProduct>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
