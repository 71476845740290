import { Hero } from "../parts/home/Hero";
import { AboutUs } from "../parts/home/AboutUs";
import { OurCategory } from "../parts/home/OurCategory";
import { BestSelling } from "../parts/home/BestSelling";
import { Banner } from "../parts/home/Banner";
import { OurPost } from "../parts/home/OurPost";
import { Maps } from "../parts/home/Maps";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
import "../assets/css/main/main.css";
import { useEffect, useState } from "react";
import { get_CategoryList, get_HomeData } from "../actions";
import YoutubeEmbed from "../components/YoutubeEmbed";
import { Promo } from "../parts/home/Promo";
import Analytics from "../helpers/Analytics";

export function Home() {
  const [isLoading, setLoading] = useState(true);
  const [bestSeller, setBestSeller] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [brandList, setBrandLits] = useState([])
  const [slideShow, setSlideShow] = useState([])
  const [blogList, setBlogList] = useState([])
  const [promoList, setPromoList] = useState([]);
  const [homeData,setHomeData] = useState({
    home_pagetext: '',
    link_video: '',
    home_pageimage: '',
  })
  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa:  ''
  })

  const getHomeData = () => {
    setLoading(true);
    get_HomeData((res) => {
      const {best_seller, best_deal, best_offers, slide_show, brand, promo, blog, setting_home} = res;
      setBestSeller(best_seller)
      setPromoList(promo);
      setHomeData(setting_home);
      setSlideShow(slide_show)
      // setCategoryList(best_offers);
      setBlogList(blog);

      setLoading(false)
    });

    get_CategoryList(res => {
      setCategoryList(res.list)
    })
  }

  useEffect(() => {
    getHomeData();

    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({...metaData, ...(JSON.parse(meta))});
        clearInterval(intervalCheckMeta);
      }
    }, 20)
  }, [])
  return (
    <>
     <Analytics title={'Home - naik daun'}></Analytics>
      <Hero isLoading={isLoading} data={homeData}></Hero>
      <AboutUs></AboutUs>
      <OurCategory isLoading={isLoading} list={categoryList}></OurCategory>
      {(bestSeller.length > 0 && !isLoading) || isLoading ? <BestSelling isLoading={isLoading} list={bestSeller}></BestSelling> : ''}
      <Banner isLoading={isLoading} list={slideShow}></Banner>
      {(isLoading || (!isLoading && promoList.length > 0)) && (<Promo isLoading={isLoading} list={promoList}></Promo>)}
      <OurPost isLoading={isLoading} list={blogList}></OurPost>
      <YoutubeEmbed embedId="121" isLoading={isLoading} data={homeData}></YoutubeEmbed>

      {metaData.coordinate ? <Maps metaData={metaData}></Maps> : ''}
    </>
  );
}
