import React, { useEffect, useState } from "react";
import img1 from "../assets/img/privacy-policy.png";
import Skeleton from "react-loading-skeleton";
import { get_OtherContent } from "../actions";
import MetaTags from "../helpers/MetaTags";
import { imageUrl } from "../actions/image";
import Analytics from "../helpers/Analytics";

export function PrivacyPolicy() {
  const [isLoading, setLoading] = useState(true);
  const [content, setContent] = useState({
    content: '',
    gambar: '',
    title: '',
  });
  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '-6.175237678515803,106.82712676006798',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: ''
  })

  useEffect(() => {
    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)
    setLoading(true)
    get_OtherContent('privacy-policy', (res) => {
      const { data } = res
      setContent(data);
      setLoading(false);
    })
  }, []);
  return (
    <>
      <Analytics title={'Privacy Policy - naik daun'}></Analytics>
      <MetaTags title={'Privacy Policy - naik daun'} url={window.location.href} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name} image={content.gambar ? imageUrl.blog(content.gambar) : img1}></MetaTags>
      <section id="privacy-policy" className="section-page">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 mb-3">
              {isLoading ? <Skeleton height={350} borderRadius={5}></Skeleton> : <img src={content.gambar ? imageUrl.blog(content.gambar) : img1} alt={'image'} className="img-right"></img>}
            </div>
            <div className="col-12 col-md-8">
              {isLoading ? <>
                <Skeleton height={50} width={200}></Skeleton>
                <div className="mt-3"></div>
                {['', '', ''].map((value, index) => (
                  <div key={`skeleton-${index}`}>
                    <Skeleton height={10} count={5}></Skeleton>
                    <Skeleton width={'40%'} height={10}></Skeleton>
                  </div>
                ))}
              </>
                : <>
                  <div className="title-section text-start">Privacy Policy</div>
                  <div className="desc mt-4" dangerouslySetInnerHTML={{ __html: content.content }}>
                  </div>
                </>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
