import Skeleton from "react-loading-skeleton";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { imageUrl } from "../../actions/image";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

export function OurCategory({ isLoading, list }) {
  const carouselRef = useRef();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };
  return (
    <>
      <section id="category">
        <div className="container">
          <div className="title-section text-center">OUR CATEGORY</div>
          <div className="sub-title-section text-center">
            Dive into Green Bliss: Discover Naik Daun's Diverse Plant Categories
          </div>
          <div className="mt-5">
            {isLoading && (
              <Carousel
                responsive={responsive}
                showDots={false}
                arrows={false}
                draggable={false}
                infinite={true}
                focusOnSelect={false}>
                {['', '', '', '', ''].map((value, index) => (
                  <div className="item-category" key={`category-skeleton${index}`}>
                    <Skeleton width={'100%'} height={'100%'}></Skeleton>
                    {/* <div className="title-item-category">BIOPESTISIDA</div> */}
                  </div>
                ))}
              </Carousel>)}
            {!isLoading && (
              <Carousel
                ref={carouselRef}
                responsive={responsive}
                showDots={false}
                arrows={true}
                draggable={false}
                infinite={false}
                // centerMode={true}
                focusOnSelect={false}>
                {list.map((value, index) => (
                  <Link to={`/product?category=${value.url}`} style={{ color: 'unset' }}>
                    <div className="item-category" key={`category-${index}`}>
                      <img src={imageUrl.product_category(value.gambar)} alt={imageUrl.product_category(value.gambar)}></img>
                      <div className="title-item-category">{value.nama}</div>
                    </div>
                  </Link>
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
