import React, { useEffect, useState } from "react";
import { Maps } from "../parts/home/Maps";
import { CardMaps } from "../components/CardMaps";
import { post_SaveMessage } from "../actions";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReactInputMask from "react-input-mask";
import MetaTags from "../helpers/MetaTags";
import { useMediaQuery } from "react-responsive";
import Analytics from "../helpers/Analytics";

export function Contact() {
  const isTabletScreen = useMediaQuery({ minWidth: 768, maxWidth: 1200});

  const [myForm, setForm] = useState({
    nama: '',
    no_hp: '',
    email: '',
    pesan: ''
  })
  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '-6.175237678515803,106.82712676006798',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: ''
  })

  const [onSubmit, setOnSubmit] = useState(false)

  const mySwal = withReactContent(Swal);

  const onSaveData = (e) => {
    e.preventDefault();
    setOnSubmit(true)
    post_SaveMessage(myForm, (res) => {
      setOnSubmit(false)
      setForm({
        nama: '',
        no_hp: '',
        email: '',
        pesan: ''
      })
      mySwal.fire({
        title: "Message Sent!",
        text: "We will contact you immediately",
        icon: "success",
        confirmButtonColor: '#034b2b'
      })
    }, () => {
      mySwal.fire({
        title: "ERROR!",
        text: "Someting wrong, please try again later",
        icon: "error",
        confirmButtonColor: '#034b2b'
      })
    })
  }

  useEffect(() => {
    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)
    // mySwal.fire({
    //   title: "Message Sent!",
    //   text: "We will contact you immediately",
    //   icon: "success",
    //   confirmButtonColor: '#1f1f23'
    // })
  }, [])
  return (
    <>
      <Analytics title={'Contact - naik daun'}></Analytics>
      <MetaTags title={'Contact - naik daun'} url={window.location.href} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name}></MetaTags>
      <section id="contact" className="section-page">
        <div className="container">
          <div className="title-section text-start">Get in touch</div>
          <div className="row mt-5">
            <div className="col-12 col-md-6 order-1 order-md-0 mb-5">
              <CardMaps className="container-maps-contact" coordinate={metaData.coordinate} address={metaData.address}></CardMaps>
            </div>
            <div className="col-12 col-md-6 order-0 order-md-1 mb-5">
              <form onSubmit={onSaveData}>
                <div className="mb-4">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                    value={myForm.nama}
                    required
                    onChange={(e) => setForm({ ...myForm, nama: e.target.value })}
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label">Phone Number</label>
                  <ReactInputMask mask="999999999999999" required={true} value={myForm.no_hp} maskChar="" className="form-control" placeholder="Enter phone number" name="no_hp" onChange={e => setForm({ ...myForm, no_hp: e.target.value })}>
                  </ReactInputMask>
                </div>
                <div className="mb-4">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={myForm.email}
                    onChange={(e) => setForm({ ...myForm, email: e.target.value })}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Message</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter your message"
                    value={myForm.pesan}
                    onChange={(e) => setForm({ ...myForm, pesan: e.target.value })}
                    required
                  />
                </div>

                <button type="submit" disabled={onSubmit} className="btn btn-green">
                  {onSubmit ? "Sending..." : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
