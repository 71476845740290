import Skeleton from "react-loading-skeleton";

export function CardBlogSkeleton(props) {
    const { image, title } = props;
    return (
      <>
        <div className="item-post">
          <Skeleton className="skeleton-image"></Skeleton>
          <div className="mt-4">
              <Skeleton width={'100%'} height={25} count={2}></Skeleton>
          </div>
        </div>
      </>
    );
  }
  