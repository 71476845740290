import React, { useEffect, useState } from "react";
import ornament from "../../assets/img/ornament.png";
import { CardBlog } from "../../components/CardBlog";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { get_BlogDetail } from "../../actions";
import Skeleton from "react-loading-skeleton";
import { CardBlogSkeleton } from "../../components/CardBlogSekeleton";
import { imageUrl } from "../../actions/image";
import { convertIsi } from "../../helpers";
import MetaTags from "../../helpers/MetaTags";
import Analytics from "../../helpers/Analytics";

export function DetailBlog() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [contentBlog, setContentBlog] = useState({
    url: '',
    judul: '',
    kategori: '',
    created_at: '',
    isi: '',
    gambar: '',
  })

  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: ''
  })

  const [recentPostList, setRecentPost] = useState([]);
  const goBack = () => {
    // if (location.key == "default") {
    navigate('/blog')
    // } else {
    //   navigate(-1)
    // }
  }

  const getContent = () => {
    setisLoading(true);
    get_BlogDetail(params.url, (res) => {
      const { data, recentPost } = res;
      setContentBlog({ ...contentBlog, ...data });
      setRecentPost(recentPost);
      setisLoading(false);
    });
  }

  useEffect(() => {
    getContent();

    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)
  }, []);

  return (
    <>
      {contentBlog.judul && (<Analytics title={ contentBlog.judul+' - naik daun'}></Analytics>)}
      <MetaTags title={contentBlog.judul ? `${contentBlog.judul} - naik daun` : 'naik daun'} url={window.location.href} description={contentBlog.isi ? convertIsi(contentBlog.isi) : metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name} date={contentBlog.created_at} image={imageUrl.blog(contentBlog.gambar)}></MetaTags>
      <section id="post-page" className="section-page">
        <img src={ornament} alt={ornament} className="ornament"></img>
        <div className="container">
          <div onClick={goBack} style={{ cursor: 'pointer' }} className="d-flex align-items-center">
            <div className="">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.38065 3.95331L2.33398 7.99998L6.38065 12.0466"
                  stroke="#1F1F23"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.6673 8H2.44727"
                  stroke="#1F1F23"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="ms-2">Back to blog</div>
          </div>
          {isLoading ?
            <>
              <div className="mt-5 text-center">
                <Skeleton className="img-blog"></Skeleton>
              </div>
              <div className="title-blog text-start font-500">
                <Skeleton height={40} width={'40%'}></Skeleton>
              </div>
              <Skeleton height={10} count={5}></Skeleton>
              <Skeleton width={'40%'} height={10}></Skeleton>
              <Skeleton height={10} count={5}></Skeleton>
              <Skeleton width={'40%'} height={10}></Skeleton>
            </>
            : <>
              <div className="mt-5 text-center">
                <img src={imageUrl.blog(contentBlog.gambar)} alt={imageUrl.blog(contentBlog.gambar)} className="img-blog"></img>
              </div>
              <div className="title-blog text-start font-500">
                {contentBlog.judul}
              </div>
              <div className="desc" dangerouslySetInnerHTML={{ __html: contentBlog.isi }}>
              </div>
            </>
          }
          {isLoading ?
            <>
              <div className="row mt-4">
                {['', '', ''].map((value, index) => (
                  <div className="col-12 col-md-4" key={`skeleton-blog${index}`}>
                    <CardBlogSkeleton></CardBlogSkeleton>
                  </div>
                ))}
              </div>
            </>
            : <>
              <div className="title-section text-start mt-5">Other Blog</div>
              <div className="row mt-5">
                {recentPostList.length > 0 ?
                  recentPostList.map((value, index) => (
                    <div className="col-12 col-md-4" key={`item-blog${index}`}>
                      <CardBlog
                        onClick={() => {
                          getContent()
                        }}
                        image={imageUrl.blog(value.gambar)}
                        title={value.judul}
                        url={value.url}></CardBlog>
                    </div>
                  )) : ''}
              </div>
            </>}
        </div>
      </section>
    </>
  );
}
