import { Link } from "react-router-dom";

export function CardBlog(props) {
  const { image, title, url, onClick } = props;
  return (
    <>
    <Link to={`/blog/${url}`} onClick={() => {
      if (typeof onClick  == "function") {
        onClick();
      }
    }}>
    <div className="item-post">
        <img src={image} alt={image}></img>
        <div className="d-flex mt-4 align-items-center">
          <div className="title">{title}</div>
          <div className="icon">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="#F1F6F0" />
              <path
                d="M25.8925 23.5354L25.8925 14.1074L16.4644 14.1074"
                stroke="#1F1F23"
                strokeWidth="2.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.1075 25.8925L25.8926 14.1074"
                stroke="#1F1F23"
                strokeWidth="2.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </Link>
    </>
  );
}
