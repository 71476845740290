

import { Helmet } from 'react-helmet-async';
import { formatDate } from '../helpers';
import logo from '../assets/img/logo192.png'
import moment from 'moment';
const defaultMetaTag = {
    title: 'naik daun',
    description: "We all need someone to lean on from time to time, and so do our plants.The Grow Moss Pole supports the growth of your plants, encouraging their natural habits. Establishing a connection between the aerial roots and the moss pole can stimulate the development of larger and more mature leaves.",
    keyword: "marketplace,plant,nature",
    language: "en",
    url: window.location.origin,
    image: logo,
    site_name: 'naikdaun',
    date: moment()
};

export default function MetaTags (props) {
    const metaTag = { ...defaultMetaTag, ...props };

    return (
        <>
            <Helmet prioritizeSeoTags={true}>
                <title>{metaTag.title}</title>
                <meta name='keywords' content={metaTag.keyword} />
                <meta name='description' content={metaTag.description} />
                <meta name='robots' content='index,follow' />
                <meta name='url' content={metaTag.url} />
                <meta name='identifier-URL' content={metaTag.url} />

                <meta name='og:title' content={metaTag.title} />
                <meta name='og:type' content='website' />
                <meta name='og:url' content={metaTag.url} />
                <meta name='og:image' content={metaTag.image} />
                <meta name='og:site_name' content={metaTag.site_name} />
                <meta name='og:description' content={metaTag.description} />

                <meta name="twitter:title" content={metaTag.title} />
                <meta name="twitter:description" content={metaTag.description} />
                <meta name="twitter:image" content={metaTag.image} />

                <meta name='date' content={formatDate(metaTag.date, 'YYYY-MM-DD')} />
                <meta name='search_date' content={formatDate(metaTag.date, 'YYYY-MM-DD')}/>
            </Helmet>
        </>
    )
}