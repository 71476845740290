import pppet from "../../assets/img/ppp-pet.svg";
import imgAbout from "../../assets/img/about-us.png";

export function AboutUs() {
  return (
    <>
      <section id="aboutUs">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-7 text-start">
              <div className="title">Moss Pole</div>
              <div className="sub-title">
                Perfect helper for your climbing plants
              </div>
              <div className="desc">
                <p>
                  We all need someone to lean on from time to time, and so do
                  our plants.
                </p>
                <p>
                  The Grow Moss Pole supports the growth of your plants,
                  encouraging their natural habits. Establishing a connection
                  between the aerial roots and the moss pole can stimulate the
                  development of larger and more mature leaves.
                </p>
              </div>
              <img src={pppet} alt={pppet} className="mt-3"></img>
            </div>
            <div className="col-12 col-md-5 text-md-end text-center">
              <img
                src={imgAbout}
                alt={imgAbout}
                className="mt-3 img-about"></img>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
