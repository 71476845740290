import bg from "../../assets/img/bg-hero.png";
import imgBanner from "../../assets/img/img-banner.png";
import logo from "../../assets/img/logo-white.png";
import "../../assets/css/home/home.css";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";

export function Hero({isLoading, data}) {
  return (
    <>
      <div id="hero">
        {isLoading ? <>
        <Skeleton width={'100%'} height={'100vh'}></Skeleton>
        </> :
        <>
        <img src={imageUrl.slide_show(data.home_pageimage)} alt={data.home_pageimage} className="bg-hero" />
        <img src={logo} alt={'logo-white'} className="logo" />
        <div className="title font-600">
        {data.home_pagetext}
        </div>
        <img src={imgBanner} alt={'banner'} className="img-banner"></img>
        </>}
      </div>
    </>
  );
}
