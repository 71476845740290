import Skeleton from "react-loading-skeleton";
import banner from "../../assets/img/banner.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { imageUrl } from "../../actions/image";

export function Banner({isLoading, list}) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: 767 },
      items: 1,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <section id="banner">
        {isLoading && (
          <Skeleton width={'100%'} height={'90vh'}></Skeleton>
        )}
        {!isLoading && (
          <Carousel
          responsive={responsive}
          showDots={false}
          arrows={false}
          draggable={false}
          infinite={true}
          focusOnSelect={false}
          autoPlay={true}
          autoPlaySpeed={3000}
          intercal={3000}
          >
            {list.map((value, index) => (
              <img src={imageUrl.slide_show(value.gambar)} alt={value.gambar} key={'12'+index} className="img-banner"></img>
            ))}
        </Carousel>
        )}
      </section>
    </>
  );
}
