import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/img/logo-green.png";

export function Navbar() {
  const location = useLocation();
  const [headerClassName, setHeaderClassName] = useState("");
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const handleScroll = (headerClassName) => {
    if (headerClassName !== "menuscroll" && window.pageYOffset > 0) {
      setHeaderClassName("menuscroll");
    } else if (headerClassName === "menuscroll" && window.pageYOffset === 0) {
      setHeaderClassName("");
    }
  };
  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]);
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg ${headerClassName} ${location.pathname !== "/" ? "bg-white" : "bg-light"
          }`}>
        <div className={`container ${headerClassName !== "menuscroll" && location.pathname == "/" ? "justify-content-center" : ""}`}>
          {headerClassName === "menuscroll" || location.pathname !== "/" ? (
            <Link to="/" className="navbar-brand">
              <img src={logo} alt={logo} className="logo"></img>
            </Link>
          ) : (
            ""
          )}
          <button
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
            className={`navbar-toggler ${headerClassName === "menuscroll" || location.pathname != "/" ? "" : "d-none"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }>
            <div
              className={` navbar-collapse ${headerClassName === "menuscroll" || location.pathname !== "/"
                  ? "justify-content-end"
                  : "justify-content-center"
                } `}
              id="navbarSupportedContent">
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                    to="/"
                    className={`${location.pathname != "/" ? "text-grey" : "active"
                      } nav-link`}
                    aria-current="page"
                    href="#">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                    to="/about-us"
                    className={`${location.pathname != "/" ? "text-grey" : ""
                      } ${location.pathname == "/about-us" ? "active" : ""
                      } nav-link`}
                    href="#">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                    to="/product"
                    className={`${location.pathname != "/" ? "text-grey" : ""
                      } ${location.pathname.indexOf("/product") >= 0 || location.pathname.indexOf("/list-promo") >= 0
                        ? "active"
                        : ""
                      } nav-link`}
                    href="#">
                    Product
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                    to="/blog"
                    className={`${location.pathname != "/" ? "text-grey" : ""
                      } ${location.pathname.indexOf("/blog") >= 0 ? "active" : ""
                      } nav-link`}
                    href="#">
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                    to="/contact"
                    className={`${location.pathname != "/" ? "text-grey" : ""
                      } ${location.pathname == "/contact" ? "active" : ""
                      } nav-link`}
                    href="#">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
