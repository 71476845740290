import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CardProduct } from "../../components/CardProduct";
import imgPromo from "../../assets/img/img-promo.png";

import { Link } from "react-router-dom";
import { CardProductSkeleton } from "../../components/CardProductSkeleton";
import { imageUrl } from "../../actions/image";

export function Promo({ isLoading, list }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2.5,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };
  return (
    <>
      <section id="bestSelling" className="section-promo">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-4 text-start position-relative">
              <img src={imgPromo} alt={imgPromo} className="img-promo"></img>
              <div className="content-wrapper">
                <div className="title-section">Our Promo</div>
                <div className="sub-title-section mt-2">
                  Discover Unbeatable Deals: Naik Daun's Top Promotions Await!
                </div>
                <Link
                  to="/our-promo"
                  className="btn btn-more secondary font-500 mt-4">
                  View More
                  <svg
                    className="ms-1"
                    width="26"
                    height="26"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M24.455 20.773L24.455 8.04511L11.7271 8.04511"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.54518 23.9548L24.4551 8.04492"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="carousel-best-selling">
                {isLoading && (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {["", "", "", ""].map((value, index) => (
                      <CardProductSkeleton
                        key={`bestseller-skeleton${index}`}></CardProductSkeleton>
                    ))}
                  </Carousel>
                )}
                {!isLoading && (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={true}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {list.map((value, index) => (
                      <CardProduct
                        discount={value.satuan_promo}
                        category={value.category}
                        name={value.nama}
                        image={imageUrl.product(value.gambar)}
                        url={value.url}
                        rating={value.rating}
                        review={value.total_review}
                        originalPrice={value.harga}
                        discountPrice={value.harga_promo}></CardProduct>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
